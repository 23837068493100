/*

Put a "data-smooth-anchoring" attribute on the <a> tag for this to work

*/
(function ($) {
  'use strict';

  $(document).ready(function () {
    $('a[data-smooth-anchoring][href*="#"]:not([href="#"])').click(function (event) {
      var element = event.currentTarget;
      var animationDuration = 1000;
      var YOffset = 80;

      if (location.pathname.replace(/^\//, '') === element.pathname.replace(/^\//, '')
        && location.hostname === element.hostname) {
        var target = $(element.hash);

        target = target.length ? target : $('[name=' + element.hash.slice(1) + ']');
        // console.log($('.header').height());
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - $('.header').height() - YOffset
          }, animationDuration);

          event.preventDefault();
        }
      }
    });
  });
}(window.jQuery.noConflict()));